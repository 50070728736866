(function($) {

  Drupal.behaviors.championLife = {
    attach: function (context, settings) {

      //$('.form-type-radio input, .form-type-checkbox input').after('<span>hh</span>');


      $("ul.pager").addClass("mt-2 list-unstyled text-center");// pager--infinite-scroll")
      $("ul.pager a").addClass("btn btn-yellow");// pager--infinite-scroll")


      $('.form-type-bef-checkbox label', context).once('bef-checkbox-pretty-checkbox',  function() {
        $(this).prepend('<span></span>');
      });

      var $resources_block = $("#block-views-resources-block");

      // Resources page / articles
      $('.view-filters', $resources_block).once('article-filters', function () {

        var categories = $('.form-checkboxes.bef-select-all-none.bef-processed, .form-checkboxes.bef-required-filter-processed', $resources_block);
        if (categories.length) {
          categories.removeClass('form-control form-checkboxes');

          var checkbox_labels = $('.bef-checkboxes label.option', categories);
          checkbox_labels.each(function( index, el ) {
            var tid = $(this).prev().val();
            var text = $(this).text().replace(/\s+/g, '-').toLowerCase();
            text = text.replace(',' , '').replace('&','');
            $(this).addClass('label--'+tid);
            $(this).addClass('label label--' + text);
          });
        }

        $('#edit-reset', $resources_block).removeClass('btn-primary');
        // $('.views-exposed-widgets #edit-reset', container).on("click touchend", function(e) {});

      });

    }
  };

  Drupal.behaviors.championLifePrettyUpload = {
    attach: function(context, settings) {

      //https://www.drupal.org/node/1421750
      // $('.field-name-field-submissionvideo span.file a').on('click',function(e){
      //   e.preventDefault();
      // });

      $('.form-managed-file').each(function(index, el) {
        //$('.file-widget', $(this)).removeClass('input-group');
        $(this).removeClass('input-group');
        $(this).once('championLifePrettyUpload'+index, function() {


          // hide the standard upload button, but not the remove button that appears after the file gets uploaded
          var $context  = $(this);
          var $formfile = $('.form-file', $context);
          //var $label_text = ' ' + $('.control-label', $context).text();

          //console.log($context.prev());

          //Submit button is for file not whole form
          var $submit = $('.form-submit', $context).not('.btn-remove');

          if($formfile.length <=0) {
            return;
          }

          $submit.hide();

          //hide the ugly "choose file" button, add a placeholder that is CSS friendly
          if(!$formfile.hasClass('.pretty-fake-upload-processed')) {
            $formfile.addClass('pretty-fake-upload-processed').hide().after('<button class="btn btn-pretty-fake-upload"><img class="svg" src="' + Drupal.settings.pathToTheme.path + '/dist/images/cloud-upload-regular.svg">Browse</button>');

            //$formfile.show(); // debugging

            $('.btn-pretty-fake-upload', $context).on('click',function(e){
              //console.log("clickity");
              $(this).prev().click();
              e.preventDefault();
            });
          }

          // submit the form when the file is picked.
          $formfile.on('change', function() {
            $submit.mousedown();
          });


        });
      });
    }
  };



  var CustomScripts = {

    // All pages
    'common': {

      // JavaScript to be fired on all pages
      init: function() {
        scriptVersion = "1.0.4";

        console.log(
          "Champion Life / master theme script version " + scriptVersion
        );
        AOS.init({
          duration: 1200,
        });

        // if($('#free-trial-banner').length > 0) {
        //   $("#header").addClass('with-alert');
        //   $("body").addClass('with-banner');
        // }

        // $('#free-trial-banner').on('closed.bs.alert', function () {
        //   $('#header').removeClass('with-banner');
        //   $("body").removeClass('with-banner');
        // });

        // Menu
        var $navbar_toggler = $("#navbar-toggler");
        //$('.offcanvas-collapse').css('top', $('#header').height());
        $('[data-toggle="offcanvas"]').on("click keyup change", function (e) {
          $(".offcanvas-collapse").toggleClass("open");
          //$('#header').toggleClass('position-fixed');
          $navbar_toggler.toggleClass("collapsed");
        });

        // Body content classes
        $(
          ".node-full .field-name-body, .node-full .field-name-field-body, .field-name-field-qanda"
        ).addClass("body-content");

        // Webform full
        //$(".node-webform-full .form-actions .btn").removeClass('btn-primary').addClass('btn-cta btn-yellow');
        $(".node-webform-full .form-actions .btn")
          .removeClass("btn-primary")
          .addClass("btn-lg btn-yellow");

        // Tooltip
        $('[data-toggle="tooltip"]').tooltip();

        // Video modals
        // Pause videos when closing modals, Play when opening
        $(".video-modal").on("hidden.bs.modal", function (e) {
          // If embed...
          var $iframe = $(this).find("iframe");
          var $videojs = $(this).find(".video-js");

          if ($iframe.length > 0) {
            $iframe[0].contentWindow.postMessage(
              '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
              "*"
            );
          } else if ($videojs.length > 0) {
            var videoId = $videojs.attr("id");
            var videoJsPlayer = videojs(videoId);
            videoJsPlayer.pause();
          }
        });

        $(".video-modal").on("shown.bs.modal", function (e) {
          // If embed...
          var $iframe = $(this).find("iframe");
          var $videojs = $(this).find(".video-js");

          if ($iframe.length > 0) {
            $iframe[0].contentWindow.postMessage(
              '{"event":"command","func":"' + "playVideo" + '","args":""}',
              "*"
            );
          } else if ($videojs.length > 0) {
            // var videoId = $videojs.attr("id");
            // var videoJsPlayer = videojs(videoId);
            // videoJsPlayer.pause();
          }
        });

        // Play Youtube videos on unmask
        $(".video-mask .mask").on("click", function (e) {
          console.log("click");
          $(this).addClass("fadeOut").removeClass("fadeIn");
          $(this)
            .next(".embed-responsive")
            .addClass("fadeIn")
            .removeClass("fadeOut");
          $(this)
            .next(".embed-responsive")
            .find("iframe")[0]
            .contentWindow.postMessage(
              '{"event":"command","func":"' + "playVideo" + '","args":""}',
              "*"
            );
        });

        // Forms
        $(".form-type-radio, .form-type-checkbox").addClass("form-check");
        $(".form-type-radio input, .form-type-checkbox input").addClass(
          "form-check-input"
        );
        $(".form-type-radio label, .form-type-checkbox input").addClass(
          "form-check-label"
        );

        $(".form-check label").prepend("<span></span>");

        // Links that just have URLs as text
        $("a:contains('http://')").addClass("text-is-link");
        $("a:contains('https://')").addClass("text-is-link");
        $("a:contains('www.')").addClass("text-is-link");

        $('.block-team .position').matchHeight({byRow: false});
        $(".block-team h4").matchHeight({ byRow: false });

        // Article teasers
        // $('.node-article-teaser').matchHeight({byRow: false});

        // // Form signup pricing
        $(".form-signup-pricing .card .pricing").matchHeight();

        // // Sport Australia
        // $(".page-ssplus .layout-hero .field-name-field-banner img, .page-ssplus-signup .layout-hero .field-name-field-banner img").addClass('cutout');

        // Movement, Mentoring, Monitoring...
        $('.key-point:not(.match').matchHeight({byRow: false});

        $(".mmm-idea").matchHeight();

        $(".mmm-idea").each(function( index, el ) {
          $(this).css('min-height', ($(this).width() + 50));
        });

        $(".mmm-idea").css('cursor', 'pointer').on('click touchend', function(e){
          if(e.target.nodeName === 'I') {
            return false;
          }
          var $href = $(this).find("a:not(.flag)").attr("href");
          e.preventDefault();

          if($href.length>0) {
            window.location = $href;
          }
          return false;
        });

        // Smooth scroll
        // Select all links with hashes
        $('a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .not("[data-toggle]")
          .click(function (event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, "") ===
                this.pathname.replace(/^\//, "") &&
              location.hostname === this.hostname
            ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length
                ? target
                : $("[name=" + this.hash.slice(1) + "]");
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();

                // if subnav exists
                var offset = target.offset().top - 70;
                if ($(".subnav").length > 0) {
                  offset -= 70;
                }

                //console.log('scroll');

                $("html, body").animate(
                  {
                    scrollTop: offset,
                  },
                  1000,
                  function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) {
                      // Checking if the target was focused
                      return false;
                    } else {
                      $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                      $target.focus(); // Set focus again
                    }
                  }
                );
              }
            }
          });
      },

      // Put JavaScript to be fired on all pages -- after page load
      finalize: function() {

        // Ripple
        //$('body.page-ripple-admin').scrollspy({ target: '#navbar-ripple-admin', offset: 120 });


        window.sr = ScrollReveal();
        window.sr.reveal('.reveal');

        if ($(window).width() > 767) {
          $("#block-partners-sponsors .views-field-field-image").matchHeight();
          $("#block-partners-sponsors .views-field-field-image .field-content").matchHeight();
        }

        // Slick
        $('.layout-testimonials .view-content').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          fade: true,
          autoplay: true,
          autoplaySpeed: 5000
        });


        //window.sr.reveal('.front section.layout h2, .front section.layout p');
        /*
         * Replace all marked SVG images with inline SVG
         */
        $('img.svg').each(function(){
            var $img     = jQuery(this);
            var imgID    = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL   = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });


        // Reveal in a ripple effect
        // sr.reveal('.my-class', { duration: 800 }, 70);
      }
    },
    // Home page specific styles
    'front': {
      init: function() {
        console.log("________________________________________");
        console.log('Welcome to Champion Life!');
        console.log("If you've opened up this panel on purpose to check out our code, then hi!");
        console.log("Keen to work with us? Hit us up at jesse@championlife.com.au :)");
        console.log("________________________________________");

        var pathToTheme = '/sites/all/themes/wellbeing/dist/images';

        $('.carousel-item').matchHeight();

      //  $('.delivery-point, .mmm-idea').matchHeight({byRow: false});

        $("#ssplus-banner").on('click', function (e) {
          window.location= $("a", this).attr("href");
        });


        /*
        function navbar_switch() {
          var scroll = $(window).scrollTop();
          var $header = $(".front #header");

          if (scroll > 70) {
            $header.removeClass('theme-transparent').addClass("theme-default bg-primary");
          } else {
            $header.removeClass("theme-default bg-primary").addClass("theme-transparent");
          }
        }

        navbar_switch();

        // Navbar scroll/init
        $(window).scroll(function() {
          navbar_switch();
        }); */


      }
    },

    // About
    // 'page_about': {
    //   init: function() {
    //   }
    // },

    // Role model
    // 'page_role_models': {
    //   init: function() {
    //     console.log("Role models");
    //     $('.node-role-model .collapse').on('hidden.bs.collapse', function () {
    //       $("#node-" + $(this).attr('data-nid')).removeClass('open');
    //     });
    //     $('.node-role-model .collapse').on('shown.bs.collapse', function () {
    //       var node = "#node-" + $(this).attr('data-nid');
    //       $(node).addClass('open');
    //       $('.node-role-model:not('+node+')').find('.collapse').collapse('hide');
    //     });

    //     $('.node-role-model .content').matchHeight({byRow: true});
    //     //$('.node-role-model').matchHeight({byRow: true, property: 'min-height'});


    //     $("#block-views-role-models-block .views-row-first .node-role-model .collapse").collapse('show');

    //     $(document).ajaxSuccess(function(){
    //       //$('.node-role-model .profession').matchHeight({byRow: true});
    //     });
    //   }
    // },

    // // Resources
    // 'page_resources': {
    //   init: function() {
    //     console.log("Resources");

    //     $(document).ajaxSuccess(function(){
    //       $('.node-article-teaser').matchHeight({byRow: true});
    //     });
    //     //var $resources_block = $("#block-views-resources-block");
    //   }
    // },

    // // Features
    // 'page_features': {
    //   init: function() {
    //     // $(window).scroll(function() {
    //     //   var window_top = $(window).scrollTop();
    //     //   var top = $('.features-nav-wrapper').offset().top;
    //     //   //console.log(window_top + ", " + (top+70));
    //     //   if ($(window).width() > 767 && (window_top+70) >= (top)) {
    //     //     $('.features-nav-wrapper').addClass('bg-primary');
    //     //   } else {
    //     //     $('.features-nav-wrapper').removeClass('bg-primary');
    //     //   }
    //     // });

    //     // console.log($(window).width());

    //     // if($(window).width() > 767) {
    //     //   $('body').scrollspy({ target: '#features-nav', offset: 120 });
    //     // }
    //   }
    // },

    // Signup
    'page_node_322346': {
      init: function() {

        if($(window).width() > 767 && $('#signupform-nav').length>0) {
          $('body').scrollspy({ target: '#signupform-nav', offset: 120 });
        }

        var $form = $('.webform-client-form-322346');
        $(".form-actions .btn").removeClass('btn-primary').addClass('btn-cta btn-yellow');
      }
    },

    // Sport Australia Signup
    // 'page_node_672531': {
    //   init: function() {

    //     if($(window).width() > 767) {
    //       $('body').scrollspy({ target: '#signupform-nav', offset: 120 });
    //     }

    //     var $form = $('#webform-client-form-672531');
    //     $(".form-actions .btn").removeClass('btn-primary').addClass('btn-cta btn-yellow');
    //   }
    // },


    // Free trial
    'page_node_861431': {
      init: function() {

        var $form = $('#webform-client-form-861436');
        $(".form-actions .btn").removeClass('btn-primary').addClass('btn-coral');
      }
    }


    // // Create your own Body Set
    // 'page_node_1049381': {
    //   init: function() {

    //     if(!$('body').hasClass('acquia-ide')) {
    //       return;
    //     }

    //     console.log("Create your own Body Set");

    //     if($(window).width() > 767 && $('#page-content-nav').length>0) {
    //       $('body').scrollspy({ target: '#page-content-nav', offset: 120 });
    //     }
    //   }
    // },

    // 'page_node_1049586': {
    //   init: function() {

    //     console.log("Create your own Body Set");

    //     if($(window).width() > 767 && $('#page-content-nav').length>0) {
    //       $('body').scrollspy({ target: '#page-content-nav', offset: 120 });
    //     }
    //   }
    // },


  };

  // **********************************************************************
  // IGNORE BEYOND HERE

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = CustomScripts;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
